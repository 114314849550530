<template>
	<div>
		<span :class="[`up-${activeBooth}`, { reset: reset }]">
			<inline-svg :src="require('../assets/video-call.svg')" width="25"></inline-svg>
			<span v-if="boothInfo.length > 0" class="info-swap">
				<router-link v-for="info in boothInfo" :key="info.id" :to="{ name: 'LiveTrack', params: { url: info.url } }">
					<strong>Live now:</strong> <span>{{ info.session.title }}</span>
				</router-link>
				<router-link :key="boothInfo[0].id + 'dup'" :to="{ name: 'LiveTrack', params: { url: boothInfo[0].url } }">
					<strong>Live now:</strong> <span>{{ boothInfo[0].session.title }}</span>
				</router-link>
			</span>
			<span v-else>No live session currently available</span>
		</span>
	</div>
</template>

<script>
	import axios from 'axios';
	import { CYIM_BEARER } from '../consts';
	import $http from '../utils/http';

	export default {
		name: 'LiveNow',

		data() {
			return {
				activeBooth: 0,
				boothInfo: [],
				interval: null,
				intervalTimeout: 120000,
				switchInterval: null,
				switchIntervalTimeout: 6000,
				allTracks: [],
				reset: false,
			};
		},

		mounted() {
			$http.get('/live-tracks').then(data => {
				this.allTracks = data.data.sort((a, b) => a.order - b.order);
				this.$emit('tracks', this.allTracks);
				this.getCurrentSession();
				this.interval = setInterval(() => {
					this.getCurrentSession();
				}, this.intervalTimeout);
			});
		},

		beforeDestroy() {
			clearInterval(this.interval);
			clearInterval(this.switchInterval);
		},

		methods: {
			async getCurrentSession() {
				const axiosWithoutAuth = axios.create({
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${CYIM_BEARER}`,
					},
				});

				let cyimdata = await axiosWithoutAuth.post('https://gateway.api.cyim.com/graphql', {
					query: `query getCurrentSessions($filter: Filter, $id: ID!) {
          program(id: $id) {
            broadcasts(filter: $filter) {
              items {
                id
                location {
                  id
                  name
                }
                session : source {
                ... on Event {
                    id
                    title
                  }
                }
              }
            }
          }
        }`,
					variables: { filter: { onAir: true }, id: '643a2c53-e538-465d-b52c-e07443236f49' },
				});

				this.boothInfo = [];

				if (cyimdata.data && cyimdata.data.data && cyimdata.data.data.program && cyimdata.data.data.program.broadcasts && cyimdata.data.data.program.broadcasts.items) {
					for (let test of cyimdata.data.data.program.broadcasts.items) {
						let url = this.allTracks.find(e => e.live_now_guid === test.location.id);
						if (url?.url) {
							this.boothInfo.push({ session: test.session, url: url.url });
						}
					}

					if (this.switchInterval) {
						clearInterval(this.switchInterval);
					}
				}

				if (this.boothInfo.length > 1) {
					this.switchInterval = setInterval(() => {
						this.activeBooth += 1;
						if (this.activeBooth === this.boothInfo.length) {
							window.setTimeout(() => {
								this.reset = true;
								this.activeBooth = 0;
							}, 1000);
							window.setTimeout(() => {
								this.reset = false;
							}, 2000);
						}
					}, this.switchIntervalTimeout);
				} else {
					this.activeBooth = 0;
				}
			},
		},
	};
</script>
